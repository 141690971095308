
import { Component, Vue, Prop } from 'vue-property-decorator'
import api from '@/utilities/api'
import { DateTime } from 'luxon'

@Component
export default class HotelRateRequestSmartLink extends Vue {
  // data // ***
  isloading = false
  isCompleted = false
  file: File | null = null
  singleRoomRates = {
    weekdayRate: 0,
    weekdayDayRate: 0,
    weekendRate: 0,
    weekendDayRate: 0,
  }
  twinDoubleRoomRates = {
    weekdayRate: 0,
    weekdayDayRate: 0,
    weekendRate: 0,
    weekendDayRate: 0,
  }
  familyRoomRates = {
    weekdayRate: 0,
    weekdayDayRate: 0,
    weekendRate: 0,
    weekendDayRate: 0,
  }
  tripleRoomRates = {
    weekdayRate: 0,
    weekdayDayRate: 0,
    weekendRate: 0,
    weekendDayRate: 0,
  }
  continentalBreakfast = 0
  fullBreakfast = 0
  lunch = 0
  dinner = 0
  validFrom: DateTime = DateTime.now()
  validTo: DateTime = DateTime.now().plus({ years: 1 })
  organisationName = null
  submittedBy = null
  submittedByEmail = null
  commission = { roomCommissionRate: 0, foodCommissionRate: 0, dynamicRate: 0 }
  termsConditions = false
  isSaving = false
  submitted = false
  errorSaving = false
  hasExistingRates = false
  checkingForExistingRates = false
  errorMessages = {
    submittedByName: null as string | null,
    submittedByEmail: null as string | null,
  }
  // props // ***
  @Prop() readonly data: any
  @Prop() readonly link: any

  // computed // ***
  get supplierName() {
    return this.data ? this.data.supplierName : ''
  }

  //created
  created() {
    this.checkingForExistingRates = true
    this.validFrom = this.data.validFrom ? DateTime.fromISO(this.data.validFrom) : DateTime.now()
    this.validTo = this.data.validFrom
      ? DateTime.fromISO(this.data.validFrom).plus({ years: 1 })
      : DateTime.now().plus({ years: 1 })
    this.organisationName = this.data.organisationName
    this.checkForExistingRates()
  }

  async onSubmit() {
    const valid = await this.validateForm()
    if (!valid) return
    else {
      let payload = {
        smartLinkId: this.link.id,
        hash: this.link.hash,
        supplierOrganisationId: this.data.organisationId,
        validFrom: this.validFrom.toISODate(),
        validTo: this.validTo.toISODate(),
        rateRequestId: this.data.hotelRateRequestId,
        roomCommissionRate: this.commission.roomCommissionRate,
        foodCommissionRate: this.commission.foodCommissionRate,
        dynamicRate: this.commission.dynamicRate,
        continentalBreakfast: this.continentalBreakfast,
        fullBreakfast: this.fullBreakfast,
        lunch: this.lunch,
        dinner: this.dinner,
        submittedBy: this.submittedBy,
        submittedEmail: this.submittedByEmail,
        entries: [
          {
            hotelRequirementTypeId: 3,
            weekdayRate: this.singleRoomRates.weekdayRate,
            weekdayDayRate: this.singleRoomRates.weekdayDayRate,
            weekendRate: this.singleRoomRates.weekendRate,
            weekendDayRate: this.singleRoomRates.weekendDayRate,
          },
          {
            hotelRequirementTypeId: 1,
            weekdayRate: this.twinDoubleRoomRates.weekdayRate,
            weekdayDayRate: this.twinDoubleRoomRates.weekdayDayRate,
            weekendRate: this.twinDoubleRoomRates.weekendRate,
            weekendDayRate: this.twinDoubleRoomRates.weekendDayRate,
          },
          {
            hotelRequirementTypeId: 132,
            weekdayRate: this.tripleRoomRates.weekdayRate,
            weekdayDayRate: this.tripleRoomRates.weekdayDayRate,
            weekendRate: this.tripleRoomRates.weekendRate,
            weekendDayRate: this.tripleRoomRates.weekendDayRate,
          },
          {
            hotelRequirementTypeId: 2,
            weekdayRate: this.familyRoomRates.weekdayRate,
            weekdayDayRate: this.familyRoomRates.weekdayDayRate,
            weekendRate: this.familyRoomRates.weekendRate,
            weekendDayRate: this.familyRoomRates.weekendDayRate,
          },
        ],
      }
      console.log(payload)
      api(`smartlink/hotelraterequest`, { data: payload, json: true, method: 'POST' }, undefined)
        .then(() => {
          this.isSaving = false
          this.submitted = true
        })
        .catch(e => {
          console.log(e)
          this.isSaving = false
          this.errorSaving = true
        })
    }
  }
  checkForExistingRates() {
    let payload = {
      organisationId: this.data.organisationId,
      validFrom: this.validFrom.toISODate(),
    }
    api(
      `smartlink/checkForExistingHotelRates`,
      { data: payload, json: true, method: 'POST' },
      undefined
    )
      .then(response => {
        console.log(response)
        this.hasExistingRates = response.data
        this.checkingForExistingRates = false
      })
      .catch(e => {
        console.log(e)
        this.checkingForExistingRates = false
      })
  }
  async validateForm(): Promise<boolean> {
    if (!this.submittedBy) {
      this.errorMessages.submittedByName = this.$t(
        'cmac.hotelRates.submittedByNameRequired'
      ) as string
    } else {
      this.errorMessages.submittedByName = null
    }
    if (!this.submittedByEmail) {
      this.errorMessages.submittedByEmail = this.$t(
        'cmac.hotelRates.submittedByEmailRequired'
      ) as string
    } else {
      this.errorMessages.submittedByEmail = null
    }
    const refs: Record<string, any> = this.$refs
    return (
      (await refs.form.validate()) &&
      !this.errorMessages.submittedByName &&
      !this.errorMessages.submittedByEmail
    )
  }
}
