
import { Component, Vue } from 'vue-property-decorator'
import { submitSupplier } from '@/utilities/onboarding'
import { OrganisationOnboardingValidationModel } from '@/models/Onboarding'
import companyForm from './components/CompanyForm.vue'
import termsAndConditions from './components/PoliciesAndTerms.vue'
import OnboardingNavbar from '@/components/nav/NavbarOnboarding.vue'
@Component({
  components: {
    'c-onboarding-navbar': OnboardingNavbar,
    'company-form': companyForm,
    'terms-and-conditions': termsAndConditions,
  },
})
export default class extends Vue {
  signupStage = 0
  formVisible = true
  isError = false
  isCompleted = false
  form = new OrganisationOnboardingValidationModel()

  saveForm(e: OrganisationOnboardingValidationModel) {
    this.form = e
    this.signupStage = 1
  }

  onBack() {
    this.signupStage = 0
    this.formVisible = true
    this.isError = false
  }

  onDuplicateFound() {
    this.formVisible = false
    this.isError = true
  }

  async onSubmit() {
    try {
      this.form.language = this.$i18n.locale
      await submitSupplier(this.form)
      this.isCompleted = true
    } catch {
      this.isError = true
    } finally {
      this.formVisible = false
    }
  }
}
