
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getFilterGradient } from '@/utilities/theme'

@Component
export default class Navbar extends Vue {
  // props // ***
  @Prop()
  links!: Record<string, any>[]

  // data // ***
  dense = true

  // computed // ***
  get navbarGradient() {
    return getFilterGradient(String(this.$vuetify.theme.themes.light.primary))
  }
}
