
import { Component, Vue, Watch } from 'vue-property-decorator'
import { COUNTRIES } from '@/utilities/constants'
import CountryLanguage from '@ladjs/country-language'
import { validateSupplier } from '@/utilities/onboarding'
import { OrganisationOnboardingValidationModel } from '@/models/Onboarding'
import { isEmailAddress, isRequired } from '@/utilities/validations'
import cmacPhoneNumber from '@/components/forms/PhoneNumberPicker.vue'
@Component({ components: { cmacPhoneNumber } })
export default class extends Vue {
  language = ''
  current = ''
  selectedCountry = { text: '', value: '' }
  onlyCountries = new Array<string>()
  languageDialogIsShowing = false
  showFormFields = false
  isRequired = isRequired
  isEmailAddress = isEmailAddress
  formVisible = true
  legalCompanyName = ''
  companyTypeIsLtd = false
  tradingName = ''
  companyRegistrationNumber = ''
  registeredAddress = ''
  operatingAddress = ''
  isSmallVehicle = false
  isLargeVehicle = false
  isCompleted = false
  isSubmitting = false
  isError = false
  fleetType = null
  errorMessages = ''
  fleetSize = 0
  mainEmailAddress = ''
  mainContactNumber = ''
  addressAutocomplete = { data: undefined }
  operatingAddressValueAutocomplete = { data: undefined }

  signupStage = 0

  mounted() {
    this.language = navigator.language
    this.current = this.$i18n.availableLocales.join()
  }

  @Watch('selectedCountry')
  onChildChanged() {
    this.onlyCountries = [this.selectedCountry.value]

    this.$forceUpdate()
    this.showFormFields = true
  }

  // computed // ***
  get addressAutocompleteErrorMessages(): string[] {
    return []
  }
  get defaultCountry(): string {
    console.log('evaluating defaultCountry:', this.selectedCountry.value)
    if (!this.selectedCountry.value) return ''
    return this.selectedCountry.value
  }
  get showOtherFleetType(): boolean {
    return true
  }
  get countries(): readonly string[][] {
    return COUNTRIES
  }
  get items(): Record<string, string>[] {
    return this.countries.map((c: string[]) => ({ text: c[0], value: c[1] }))
  }

  resetValidation() {
    this.$store.commit('core/resetErrors')
  }

  async onValidateForm() {
    const valid = await this.validateForm()

    if (!valid) return

    const model = new OrganisationOnboardingValidationModel({
      companyRegistrationNumber: this.companyRegistrationNumber,
      companyTypeLtd: this.companyTypeIsLtd,
      emailAddress: this.mainEmailAddress,
      legalCompanyName: this.legalCompanyName,
      tradingName: this.tradingName,
      phoneNumber: this.mainContactNumber,
      operatingAddress: this.addressAutocomplete.data,
      registeredAddress: this.operatingAddressValueAutocomplete.data,
      fleetSize: this.fleetSize,
      isLargeVehicle: this.isLargeVehicle,
      isSmallVehicle: this.isSmallVehicle,
      countryCode: this.selectedCountry.value,
    })

    try {
      this.isSubmitting = true
      await validateSupplier(model)
      this.$emit('form-saved', model)
    } catch (error) {
      this.$emit('duplicateFound')
    } finally {
      this.isSubmitting = false
    }
  }

  async validateForm(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    return await refs.form.validate()
  }

  checkIfLanguageSwitchSupported(e: any) {
    const languages = CountryLanguage.getCountryLanguages(e.value)
    console.log('checkIfLanguageSwitchSupported', languages, e.value, this.$i18n.availableLocales)
    if (languages && languages.length > 0) {
      const localeLanguage = e.value === 'IT' ? 'it' : languages[0].iso639_1
      const currentLocale = this.$i18n.locale
      if (currentLocale == localeLanguage) return

      const language = this.$i18n.availableLocales.find(
        x => x.toLocaleLowerCase() == localeLanguage.toLocaleLowerCase()
      )

      //Do we support that language?
      if (!language) {
        console.log('checkIfLanguageSwitchSupported#-----#2', language)
        //not found default en
        this.$i18n.locale = 'en'
        return
      }
      console.log('checkIfLanguageSwitchSupported#-----#4', language)
      this.$i18n.locale = language
    } else {
      this.$i18n.locale = 'en'
    }
  }
}
