
import { Component, Vue, Prop } from 'vue-property-decorator'
import { uploadRequestInvoice } from '@/utilities/smartLink'
import { DateTime } from 'luxon'

@Component
export default class SmartLink extends Vue {
  // data // ***
  isloading = false
  isCompleted = false
  file: File | null = null
  // props // ***
  @Prop() readonly data: any
  @Prop() readonly link: any

  // computed // ***
  get supplierName() {
    return this.data ? this.data.supplierName : ''
  }

  get jobId() {
    return this.data ? this.data.jobId : ''
  }
  get cardNumber() {
    return this.data ? this.data.cardNumber : ''
  }

  get amount() {
    return this.data ? this.data.amount : 0
  }

  get disableUploadButton() {
    return this.file == null
  }

  get issuedDate() {
    return this.data ? DateTime.fromISO(this.data.dateIssued).toLocaleString(DateTime.DATE_MED) : ''
  }

  async upload() {
    console.log('start loading data', this.file)
    var formData = new FormData()
    formData.append('file', this.file!)
    formData.append('jobId', this.jobId)
    formData.append('hash', this.link.hash)
    formData.append('smartLinkId', this.link.id)
    formData.append('supplierOrganisationId', this.data.supplierId)
    formData.append('supplierOrganisationName', this.data.supplierName)
    formData.append('deploymentId', this.data.deploymentId)
    formData.append('dateIssued', this.data.dateIssued)
    formData.append('cardNumber', this.data.cardNumber)
    formData.append('amount', this.data.amount)
    try {
      this.isloading = true
      await uploadRequestInvoice(formData)
      this.isCompleted = true
    } catch (error) {
      console.error(error)
    } finally {
      this.isloading = false
    }
  }
}
