
import { Component, Vue, Watch } from 'vue-property-decorator'
import { COOKIE_KEYS } from '@/utilities/constants'

@Component
export default class Login extends Vue {
  // data // ***
  loading = false

  // computed // ***
  get authenticated() {
    return this.$auth.authenticated
  }

  @Watch('authenticated')
  onAuthenticated(v: any) {
    if (!v && this.$route.path !== '/login') this.$router.push('/login')
    else this.resetCookies()
  }

  // mounted // ***
  mounted() {
    if (!this.authenticated && this.$route.path !== '/login') this.$router.push('/login')
    else this.resetCookies()
  }

  // methods // ***
  logIn() {
    this.loading = true
    this.$store
      .dispatch('core/logIn')
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        this.loading = false
      })
  }
  resetCookies(): void {
    COOKIE_KEYS.forEach((k: string) => this.$cookies.remove(k))
  }
}
