
import utils, { country, getCountry, setCaretPosition } from '@/utilities/phone'
import PhoneNumber from 'awesome-phonenumber'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

function getDefault(key: any): any {
  //@ts-ignore
  return utils.options[key]
}

// Polyfill for Event.path in IE 11: https://stackoverflow.com/a/46093727
function getParents(node: any, memo?: any): any {
  const parsedMemo = memo || []
  const { parentNode } = node
  if (!parentNode) {
    return parsedMemo
  }
  return getParents(parentNode, parsedMemo.concat(parentNode))
}

@Component({
  directives: {
    'click-outside': {
      bind(el: any, binding: any, vNode: any) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name
          let warn = `[Vue-click-outside:] provided expression ${binding.expression} is not a function, but has to be`
          if (compName) {
            warn += `Found in component ${compName}`
          }
          console.warn(warn)
        }
        // Define Handler and cache it on the element
        const { bubble } = binding.modifiers
        const handler = (e: any) => {
          // Fall back to composedPath if e.path is undefined
          const path = e.path || (e.composedPath ? e.composedPath() : false) || getParents(e.target)
          if (bubble || (path.length && !el.contains(path[0]) && el !== path[0])) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        // add Event Listeners
        document.addEventListener('click', handler)
      },
      unbind(el) {
        // Remove Event Listeners
        //@ts-ignore
        document.removeEventListener('click', el.__vueClickOutside__)
        //@ts-ignore
        el.__vueClickOutside__ = null
      },
    },
  },
})
export default class extends Vue {
  @Prop([Array, String]) readonly messages: Array<string> | string | undefined
  @Prop([Array, String]) readonly errorMessages: Array<string> | string | undefined
  @Prop([Array, String]) readonly successMessages: Array<string> | string | undefined
  @Prop(String) readonly hint: string | undefined
  @Prop(String) readonly suffix: string | undefined
  @Prop(String) readonly prefix: string | undefined
  @Prop({ type: String, default: '' }) readonly appendIcon: string | undefined
  @Prop(String) readonly backgroundColor: string | undefined
  @Prop(String) readonly color: string | undefined
  @Prop({ type: Array, default: () => [] }) readonly rules: Array<any> | undefined
  @Prop({ type: [Number, String], default: 2 }) readonly loaderHeight: number | string | undefined
  @Prop({ type: [Boolean, String], default: false }) readonly loading: boolean | string | undefined
  @Prop({ type: [Boolean, String], default: false }) readonly hideDetails:
    | boolean
    | string
    | undefined
  @Prop({ type: Boolean, default: false }) readonly clearable: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly filled: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly fullWidth: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly flat: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly light: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly dark: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly validateOnBlur: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly outlined: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly dense: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly persistentHint: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly readonly: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly error: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly success: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly shaped: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly singleLine: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly rounded: boolean | undefined
  @Prop({ type: Boolean, default: false }) readonly persistentPlaceholder: boolean | undefined
  @Prop({ type: String, default: '' }) readonly value: string | undefined
  @Prop({ type: String, default: '' }) readonly inputHeight: string | undefined
  @Prop({ type: String, default: () => getDefault('label') }) readonly label: string | undefined
  @Prop({ type: String, default: '' }) readonly selectLabel: string | undefined
  @Prop({ type: [String, Array, Object] }) readonly menuProps:
    | string
    | Array<any>
    | Record<string, unknown>
    | undefined
  @Prop({ type: String, default: () => getDefault('placeholder') }) readonly placeholder:
    | string
    | undefined
  @Prop({ type: Boolean, default: () => getDefault('required') }) readonly required:
    | boolean
    | undefined
  @Prop({ type: Boolean, default: () => getDefault('disabledFetchingCountry') })
  readonly disabledFetchingCountry: boolean | undefined
  @Prop({ type: Boolean, default: () => getDefault('disabled') }) readonly disabled:
    | boolean
    | undefined
  @Prop({ type: Boolean, default: () => getDefault('autofocus') }) readonly autofocus:
    | boolean
    | undefined
  @Prop({ type: String, default: () => getDefault('mode') }) readonly mode: string | undefined
  @Prop({ type: String, default: () => getDefault('defaultCountry') }) readonly defaultCountry:
    | string
    | undefined
  @Prop({ type: String, default: () => getDefault('autocomplete') }) readonly autocomplete:
    | string
    | undefined
  @Prop({ type: String, default: () => getDefault('invalidMsg') }) readonly invalidMsg:
    | string
    | undefined
  @Prop({ type: String, default: () => getDefault('name') }) readonly name: string | undefined
  @Prop({ type: String, default: () => getDefault('inputId') }) readonly inputId: string | undefined
  @Prop({ type: Array, default: () => getDefault('allCountries') }) readonly allCountries:
    | Array<country>
    | undefined
  @Prop({ type: Array, default: () => getDefault('preferredCountries') })
  readonly preferredCountries!: Array<string>
  @Prop({ type: Array, default: () => getDefault('onlyCountries') })
  readonly onlyCountries!: Array<string>

  @Prop({ type: Array, default: () => getDefault('ignoredCountries') })
  readonly ignoredCountries!: Array<string>

  @Prop({ type: [String, Array, Object], default: () => getDefault('wrapperClasses') })
  readonly wrapperClasses: string | Record<string, unknown> | Array<any> | undefined
  @Prop({ type: [String, Array, Object], default: () => '' }) readonly textFieldClasses!:
    | string
    | Record<string, unknown>
    | Array<any>

  @Prop({ type: [String, Array, Object], default: () => '' }) readonly selectClasses!:
    | string
    | Record<string, unknown>
    | Array<any>

  @Prop({ type: [String, Array, Object], default: () => '' }) readonly inputClasses!:
    | string
    | Record<string, unknown>
    | Array<any>

  @Prop({ type: [String, Array, Object], default: () => getDefault('inputOptions') })
  readonly inputOptions!: Record<string, unknown>
  @Prop({ type: Number, default: () => getDefault('maxLen') }) readonly maxLen!: number

  mounted() {
    this.$watch('$refs.countryInput.isResetting', v => v && this.reset())
    this.reset()
  }
  created() {
    if (this.value) {
      this.phone = this.value.trim()
    }
  }
  //data

  private phone = ''
  private activeCountry: country = { iso2: '', areaCodes: '', dialCode: '', name: '', priority: 0 }
  private open = false
  private finishMounted = false
  private selectedIndex: number | null = null
  private typeToFindInput = ''
  private typeToFindTimer = null
  private cursorPosition = 0
  private countryCode: country | null = null

  //computed
  get parsedMode() {
    if (this.mode) {
      if (!['international', 'national'].includes(this.mode)) {
        console.error('Invalid value of prop "mode"')
      } else {
        return this.mode
      }
    }
    if (!this.phone || this.phone[0] !== '+') {
      return 'national'
    }
    return 'international'
  }
  get filteredCountries(): country[] | undefined {
    // List countries after filtered
    if (this.onlyCountries?.length) {
      return this.getCountries(this.onlyCountries)
    }
    if (this.ignoredCountries?.length) {
      return this.allCountries?.filter(
        ({ iso2 }) =>
          !this.ignoredCountries?.includes(iso2.toUpperCase()) &&
          !this.ignoredCountries?.includes(iso2.toLowerCase())
      )
    }
    return this.allCountries
  }
  get sortedCountries() {
    // Sort the list countries: from preferred countries to all countries
    const preferredCountries = this.getCountries(this.preferredCountries).map(country => ({
      ...country,
      preferred: true,
    }))
    return [...preferredCountries, ...this.filteredCountries!]
  }
  get phoneObject() {
    const result = new PhoneNumber(this.phone || '', this.activeCountry.iso2).toJSON()
    Object.assign(result, {
      isValid: result.valid,
      country: this.activeCountry,
    })
    if (!this.phone) {
      return {
        ...result,
        number: {
          input: '',
        },
      }
    }
    return result
  }
  get phoneText() {
    let key = 'input'
    if (this.phoneObject.valid) {
      key = this.parsedMode
    }
    return this.phoneObject.number[key] || ''
  }

  //methods
  initializeCountry() {
    return new Promise(resolve => {
      /**
       * 1. If the phone included prefix (+12), try to get the country and set it
       */
      if (this.phone && this.phone[0] === '+') {
        const activeCountry = new PhoneNumber(this.phone).getRegionCode()
        if (activeCountry) {
          this.choose(activeCountry)
          resolve('')
          return
        }
      }
      /**
       * 2. Use default country if passed from parent
       */
      if (this.defaultCountry) {
        const defaultCountry = this.findCountry(this.defaultCountry)
        if (defaultCountry) {
          this.choose(defaultCountry)
          resolve('')
          return
        }
      }
      const fallbackCountry =
        this.findCountry(this.preferredCountries[0]) || this.filteredCountries![0]
      /**
       * 3. Check if fetching country based on user's IP is allowed, set it as the default country
       */
      if (!this.disabledFetchingCountry) {
        getCountry()
          .then(res => {
            if (this.phone === '') {
              this.activeCountry = this.findCountry(res) || this.activeCountry
            }
          })
          .catch(error => {
            console.warn(error)
            /**
             * 4. Use the first country from preferred list (if available) or all countries list
             */
            this.choose(fallbackCountry)
          })
          .finally(() => {
            resolve('')
          })
      } else {
        /**
         * 4. Use the first country from preferred list (if available) or all countries list
         */
        this.choose(fallbackCountry)
        resolve('')
      }
    })
  }
  /**
   * Get the list of countries from the list of iso2 code
   */
  getCountries(list: Array<string> = []): Array<country> {
    //@ts-ignore
    return list.map(countryCode => this.findCountry(countryCode)).filter(Boolean)
  }
  findCountry(iso = '') {
    return this.allCountries?.find(country => country.iso2 === iso.toUpperCase())
  }
  getItemClass(index: number, iso2: string) {
    const highlighted = this.selectedIndex === index
    const lastPreferred = index === this.preferredCountries!.length - 1
    const preferred = this.preferredCountries!.some(c => c.toUpperCase() === iso2)
    return {
      highlighted,
      'last-preferred': lastPreferred,
      preferred,
    }
  }
  choose(country: any, toEmitInputEvent = false) {
    console.debug('choose', country, toEmitInputEvent)
    this.activeCountry = country || this.activeCountry || {}
    if (
      this.phone &&
      this.phone[0] === '+' &&
      this.activeCountry.iso2 &&
      this.phoneObject.number.significant
    ) {
      // Attach the current phone number with the newly selected country
      this.phone = new PhoneNumber(
        this.phoneObject.number.significant,
        this.activeCountry.iso2
      ).getNumber('international')
    } else if (this.inputOptions && this.inputOptions.showDialCode && country) {
      // Reset phone if the showDialCode is set
      this.phone = `+${country.dialCode}`
    }
    if (toEmitInputEvent) {
      this.$emit('input', this.phoneText, this.phoneObject)
    }
  }
  onInput(e: any) {
    // this.$refs.input.setCustomValidity(
    //   this.phoneObject.valid ? "" : this.invalidMsg
    // );
    // Returns response.number to assign it to v-model (if being used)
    // Returns full response for cases @input is used
    // and parent wants to return the whole response.
    this.$emit('input', this.phoneText, this.phoneObject)
    this.$emit('onInput', this.phoneObject) // Deprecated
    // Keep the current cursor position just in case the input reformatted
    // and it gets moved to the last character.
    if (e && e.target) {
      this.cursorPosition = e.target.selectionStart
    }
  }
  onBlur() {
    this.$emit('blur')
    this.$emit('onBlur') // Deprecated
  }
  onFocus(event: any) {
    this.$emit('focus', event)
  }
  onClick(event: any) {
    this.$emit('click', event)
  }
  onChange(value: any) {
    this.$emit('change', value)
  }
  onMouseUp(event: any) {
    this.$emit('mouseup', event)
  }
  onMouseDown(event: any) {
    this.$emit('mousedown', event)
  }
  onKeyDown(event: any) {
    this.$emit('keydown', event)
  }
  onEnter() {
    this.$emit('enter')
  }
  onSpace() {
    this.$emit('space')
  }
  focus() {
    //@ts-ignore
    this.$refs.input.focus()
  }
  toggleDropdown() {
    if (this.disabled) {
      return
    }
    this.open = !this.open
  }
  clickedOutside() {
    this.open = false
  }
  keyboardNav(e: any) {
    if (e.keyCode === 40) {
      // down arrow
      e.preventDefault()
      this.open = true
      if (this.selectedIndex === null) {
        this.selectedIndex = 0
      } else {
        this.selectedIndex = Math.min(this.sortedCountries.length - 1, this.selectedIndex + 1)
      }
      //@ts-ignore
      const selEle = this.$refs.list.children[this.selectedIndex]
      if (
        selEle.offsetTop + selEle.clientHeight >
        //@ts-ignore
        this.$refs.list.scrollTop + this.$refs.list.clientHeight
      ) {
        //@ts-ignore
        this.$refs.list.scrollTop =
          //@ts-ignore
          selEle.offsetTop - this.$refs.list.clientHeight + selEle.clientHeight
      }
    } else if (e.keyCode === 38) {
      // up arrow
      e.preventDefault()
      this.open = true
      if (this.selectedIndex === null) {
        this.selectedIndex = this.sortedCountries.length - 1
      } else {
        this.selectedIndex = Math.max(0, this.selectedIndex - 1)
      }
      //@ts-ignore
      const selEle = this.$refs.list.children[this.selectedIndex]
      //@ts-ignore
      if (selEle.offsetTop < this.$refs.list.scrollTop) {
        //@ts-ignore
        this.$refs.list.scrollTop = selEle.offsetTop
      }
    } else if (e.keyCode === 13) {
      // enter key
      if (this.selectedIndex !== null) {
        this.choose(this.sortedCountries[this.selectedIndex])
      }
      this.open = !this.open
    } else {
      // typing a country's name
      this.typeToFindInput += e.key
      //@ts-ignore
      clearTimeout(this.typeToFindTimer)
      //@ts-ignore
      this.typeToFindTimer = setTimeout(() => {
        this.typeToFindInput = ''
      }, 700)
      // don't include preferred countries so we jump to the right place in the alphabet
      const typedCountryI = this.sortedCountries
        .slice(this.preferredCountries!.length)
        //@ts-ignore
        .findIndex(c => c.name.toLowerCase().startsWith(this.typeToFindInput))
      if (typedCountryI >= 0) {
        this.selectedIndex = this.preferredCountries!.length + typedCountryI
        //@ts-ignore
        const selEle = this.$refs.list.children[this.selectedIndex]
        //@ts-ignore
        const needToScrollTop = selEle.offsetTop < this.$refs.list.scrollTop
        const needToScrollBottom =
          selEle.offsetTop + selEle.clientHeight >
          //@ts-ignore
          this.$refs.list.scrollTop + this.$refs.list.clientHeight
        if (needToScrollTop || needToScrollBottom) {
          //@ts-ignore
          this.$refs.list.scrollTop = selEle.offsetTop - this.$refs.list.clientHeight / 2
        }
      }
    }
  }
  reset() {
    this.countryCode = this.activeCountry
    this.initializeCountry()
      .then(() => {
        if (
          !this.phone &&
          this.inputOptions &&
          this.inputOptions.showDialCode &&
          //@ts-ignore
          this.activeCountry.dialCode
        ) {
          this.phone = `+${this.activeCountry.dialCode}`
        }
        this.countryCode = this.activeCountry
        this.$emit('validate', this.phoneObject)
        this.$emit('onValidate', this.phoneObject) // Deprecated
      })
      .catch(console.error)
      .finally(() => {
        this.finishMounted = true
      })
    this.open = false
  }
  onChangeCountryCode() {
    this.choose(this.countryCode, true)
  }

  //Watch

  @Watch('filteredCountries')
  onFilteredCountriesChanged() {
    if (this.filteredCountries!.length > 0) {
      this.countryCode = this.activeCountry = this.filteredCountries![0]
      this.choose(this.activeCountry, true)
    }
    console.debug('@watch:filteredCountries', this.activeCountry)
  }

  @Watch('phoneObject.valid')
  onPhoneObjectChange(value: any) {
    if (value) {
      this.phone = this.phoneText
    }
    this.$emit('validate', this.phoneObject)
    this.$emit('onValidate', this.phoneObject) // Deprecated
  }
  @Watch('value')
  onValueChanged() {
    this.phone = this.value!
  }

  @Watch('open')
  onOpenChanged(isDropdownOpened: boolean) {
    // Emit open and close events
    if (isDropdownOpened) {
      this.$emit('open')
    } else {
      this.$emit('close')
    }
  }
  @Watch('phone')
  onPhoneChanged(newValue: string, oldValue: string) {
    if (newValue) {
      if (newValue[0] === '+') {
        const code = new PhoneNumber(newValue).getRegionCode()
        if (code) {
          this.activeCountry = this.findCountry(code) || this.activeCountry
        }
      }
    }
    // Reset the cursor to current position if it's not the last character.
    if (oldValue && this.cursorPosition < oldValue.length) {
      this.$nextTick(() => {
        setCaretPosition(this.$refs.input, this.cursorPosition)
      })
    }

    this.$emit('input', this.phoneText, this.phoneObject)
  }
  @Watch('activeCountry')
  onActiveCountryChanged(value: country | undefined) {
    console.debug('@Watch:activeCountry', value)
    if (value && value.iso2) {
      this.$emit('country-changed', value)
    }
  }
}
