export interface Address {
  address1: string
  address2: string
  town: string
  region: string
  country: string
  postCode: string
  iSOCountryCode: string
  lat: number | null
  long: number | null
}

export class OrganisationOnboardingValidationModel {
  public legalCompanyName!: string
  public companyTypeLtd!: boolean
  public tradingName!: string
  public companyRegistrationNumber!: string
  public registeredAddress!: Address
  public operatingAddress!: Address
  public emailAddress!: string
  public phoneNumber!: string
  public isSmallVehicle!: boolean
  public isLargeVehicle!: boolean
  public fleetSize!: number
  public countryCode!: string
  public language!: string

  public constructor(init?: Partial<OrganisationOnboardingValidationModel>) {
    ;(Object as any).assign(this, init)
  }
}

export enum FleetType {
  SmallVehicle = 'SmallVehicle',
  LargeVehicle = 'LargeVehicle',
  Other = 'Other',
}
