export class AvailabilityItem {
  description: string | null = null
  type: AvailabilityItemType | null = null
  itemTypeId: number | null = null
  numberAvailable: number | null = null
  rate: number | null = null
  public constructor(init?: Partial<AvailabilityItem>) {
    ;(Object as any).assign(this, init)
  }
}

export enum AvailabilityItemType {
  Taxi,
  Coach,
  Hotel,
}

export class SupplierAvailabilityRequestModel {
  items: AvailabilityItem[] = []
  smartLinkId: string | null = null
  supplierId: string | null = null
  submittedBy: string | null = null
}
