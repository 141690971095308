
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import availabilityItemComponent from './availabilityItem.vue'
import { AvailabilityItem, AvailabilityItemType } from '@/models/AvailabilityItem'

@Component({ components: { 'availability-item-component': availabilityItemComponent } })
export default class SmartLink extends Vue {
  // props // ***
  @Prop() readonly isTaxi: boolean = false
  @Prop() readonly existingRecords: AvailabilityItem[] | undefined

  @Watch('existingRecords')
  onChildChanged(val: AvailabilityItem[]) {
    for (const item of this.availabilityItems) {
      const record = val.find(x => x.itemTypeId == item.itemTypeId && x.type == item.type)
      if (record) {
        item.numberAvailable = record.numberAvailable
        item.rate = record.rate
      }
    }
  }

  availabilityItems = [
    new AvailabilityItem({
      description: '16 Seater',
      itemTypeId: 5,
      numberAvailable: 0,
      type: AvailabilityItemType.Coach,
    }),
    new AvailabilityItem({
      description: '24 Seater',
      itemTypeId: 10,
      numberAvailable: 0,
      type: AvailabilityItemType.Coach,
    }),
    new AvailabilityItem({
      description: '32 Seater',
      itemTypeId: 16,
      numberAvailable: 0,
      type: AvailabilityItemType.Coach,
    }),
    new AvailabilityItem({
      description: '49 Seater',
      itemTypeId: 76,
      numberAvailable: 0,
      type: AvailabilityItemType.Coach,
    }),
    new AvailabilityItem({
      description: 'Double Decker Coach',
      itemTypeId: 156,
      numberAvailable: 0,
      type: AvailabilityItemType.Coach,
    }),
    new AvailabilityItem({
      description: 'Double Decker Bus',
      itemTypeId: 1180,
      numberAvailable: 0,
      type: AvailabilityItemType.Coach,
    }),
  ]

  mounted() {
    if (!this.isTaxi)
      this.availabilityItems.unshift(
        new AvailabilityItem({
          description: '8 Seater',
          itemTypeId: 2,
          numberAvailable: 0,
          type: AvailabilityItemType.Taxi,
          rate: 0,
        })
      )
  }
}
