
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import availabilityItemComponent from './availabilityItem.vue'
import { AvailabilityItem, AvailabilityItemType } from '@/models/AvailabilityItem'

@Component({ components: { 'availability-item-component': availabilityItemComponent } })
export default class SmartLink extends Vue {
  @Prop() readonly existingRecords: AvailabilityItem[] | undefined

  @Watch('existingRecords')
  onChildChanged(val: AvailabilityItem[]) {
    for (const item of this.availabilityItems) {
      const record = val.find(x => x.itemTypeId == item.itemTypeId && x.type == item.type)
      if (record) {
        item.numberAvailable = record.numberAvailable
        item.rate = record.rate
      }
    }
    this.$forceUpdate()
  }

  availabilityItems = [
    new AvailabilityItem({
      description: 'Rooms',
      itemTypeId: 1,
      numberAvailable: 0,
      type: AvailabilityItemType.Hotel,
      rate: 0,
    }),
  ]
}
