
import { Component, Vue } from 'vue-property-decorator'
import { getSmartLink, getPaymentLink } from '@/utilities/smartLink'
import invoiceRequestUploadLinkComponent from './components/invoiceUploadRequest.vue'
import availabilityRequestComponent from './components/availabilityRequest.vue'
import hotelRateRequestComponent from './components/hotelRateRequest.vue'
@Component({
  components: {
    invoiceRequestUploadLinkComponent,
    availabilityRequestComponent,
    hotelRateRequestComponent,
  },
})
export default class SmartLink extends Vue {
  // data // ***
  loading = false
  smartLinkData = null
  link = null
  hasError = false
  errorMessage = ''
  linkType = -1

  // mounted // ***
  mounted() {
    const extractedData = this.$route.query.d as string
    getSmartLink(this.$route.params.id, extractedData)
      .then(x => {
        console.log(x)
        if (x == null) {
          this.hasError = true
          return
        }
        this.smartLinkData = x.data
        this.link = x.link
        this.linkType = x.link.linkType
      })
      .catch(() => {
        const errors = this.$store.getters['core/smartLinkErrors']
        this.errorMessage = errors.message
        this.hasError = true
      })
  }

  // methods // ***
  redirectToStripePaymentPage() {
    getPaymentLink(this.$route.params.id).then(x => {
      window.location.href = x!
    })
  }
}
