var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",staticClass:"uploadForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onValidateForm.apply(null, arguments)}}},[_c('v-autocomplete',{attrs:{"hide-details":"","items":_vm.items,"return-object":"","label":_vm.$t('onboarding.country')},on:{"input":_vm.checkIfLanguageSwitchSupported},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),(_vm.showFormFields)?[_c('v-text-field',{attrs:{"label":_vm.$t('onboarding.legalCompanyName'),"rules":[() => !!_vm.legalCompanyName || _vm.$t('validations.thisFieldIsRequired')],"error-messages":_vm.errorMessages},model:{value:(_vm.legalCompanyName),callback:function ($$v) {_vm.legalCompanyName=$$v},expression:"legalCompanyName"}}),_c('v-text-field',{attrs:{"label":_vm.$t('onboarding.tradingName'),"rules":[() => !!_vm.tradingName || _vm.$t('validations.thisFieldIsRequired')],"error-messages":_vm.errorMessages},model:{value:(_vm.tradingName),callback:function ($$v) {_vm.tradingName=$$v},expression:"tradingName"}}),_c('v-text-field',{attrs:{"label":_vm.$t('onboarding.companyRegistrationNumber')},model:{value:(_vm.companyRegistrationNumber),callback:function ($$v) {_vm.companyRegistrationNumber=$$v},expression:"companyRegistrationNumber"}}),_c('c-address-autocomplete',{attrs:{"useApiAuth":true,"country":_vm.defaultCountry,"rules":[() => !!_vm.addressAutocomplete.data || _vm.$t('validations.thisFieldIsRequired')],"label":_vm.$t('onboarding.registeredAddress'),"error-messages":_vm.addressAutocompleteErrorMessages},model:{value:(_vm.addressAutocomplete),callback:function ($$v) {_vm.addressAutocomplete=$$v},expression:"addressAutocomplete"}}),_c('c-address-autocomplete',{attrs:{"useApiAuth":true,"country":_vm.defaultCountry,"rules":[
        () => !!_vm.operatingAddressValueAutocomplete.data || _vm.$t('validations.thisFieldIsRequired'),
      ],"label":_vm.$t('onboarding.operatingAddress'),"error-messages":_vm.addressAutocompleteErrorMessages},model:{value:(_vm.operatingAddressValueAutocomplete),callback:function ($$v) {_vm.operatingAddressValueAutocomplete=$$v},expression:"operatingAddressValueAutocomplete"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('onboarding.smallVehicle'),"rules":[
        () => _vm.isSmallVehicle || _vm.isLargeVehicle || _vm.$t('validations.mustBeAtLeastOneChecked'),
      ],"color":"indigo","error-messages":_vm.addressAutocompleteErrorMessages},model:{value:(_vm.isSmallVehicle),callback:function ($$v) {_vm.isSmallVehicle=$$v},expression:"isSmallVehicle"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('onboarding.largeVehicle'),"color":"indigo","rules":[
        () => _vm.isSmallVehicle || _vm.isLargeVehicle || _vm.$t('validations.mustBeAtLeastOneChecked'),
      ],"error-messages":_vm.addressAutocompleteErrorMessages},model:{value:(_vm.isLargeVehicle),callback:function ($$v) {_vm.isLargeVehicle=$$v},expression:"isLargeVehicle"}}),_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('onboarding.fleetSize'),"rules":[() => _vm.fleetSize > 0 || _vm.$t('validations.thisFieldIsRequired')],"error-messages":_vm.errorMessages},model:{value:(_vm.fleetSize),callback:function ($$v) {_vm.fleetSize=$$v},expression:"fleetSize"}}),_c('v-text-field',{attrs:{"label":_vm.$t('onboarding.emailAddress'),"rules":[_vm.isEmailAddress, _vm.isRequired],"error-messages":_vm.errorMessages},model:{value:(_vm.mainEmailAddress),callback:function ($$v) {_vm.mainEmailAddress=$$v},expression:"mainEmailAddress"}}),_c('cmacPhoneNumber',{attrs:{"disabledFetchingCountry":"","inputOptions":{ showDialCode: true },"validate-on-blur":"","required":"","defaultCountry":_vm.defaultCountry,"onlyCountries":_vm.onlyCountries,"rules":[
        () =>
          (!!_vm.mainContactNumber && _vm.mainContactNumber.length > 8) ||
          _vm.$t('validations.thisFieldIsRequired'),
      ],"error-messages":_vm.errorMessages},model:{value:(_vm.mainContactNumber),callback:function ($$v) {_vm.mainContactNumber=$$v},expression:"mainContactNumber"}}),_c('p',[_c('v-btn',{attrs:{"elevation":"2","loading":_vm.isSubmitting,"disabled":_vm.isSubmitting,"type":"submit"}},[_vm._v(_vm._s(_vm.$t('common.next'))+" >")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }