import api from '@/utilities/api'
import { OrganisationOnboardingValidationModel } from '@/models/Onboarding'

export const validateSupplier = async (model: OrganisationOnboardingValidationModel) => {
  await api(`supplieronboarding/validate`, { method: 'POST' }, { data: model })
}

export const submitSupplier = async (model: OrganisationOnboardingValidationModel) => {
  await api(`supplieronboarding/signup`, { method: 'POST' }, { data: model })
}

export const getOrganisationProfile = async (id: string): Promise<any> => {
  const result = await api(`supplieronboarding/getorganisation?id=${id}`, undefined, undefined)
  return result.data
}
