import { AvailabilityItem } from '@/models/AvailabilityItem'
import api from '@/utilities/api'

export const getSmartLink = async (
  id: string,
  data: string
): Promise<Record<string, any> | null> => {
  const res = await api(`smartlink/use/${id}?d=${btoa(data)}`, undefined, undefined)
  return res.data
}

export const getPaymentLink = async (id = ''): Promise<string | null> => {
  const res = await api(`smartlink/createPaymentLink/${id}`, undefined, undefined)
  return res.data
}

export const sendAvailaiblityResponse = async (data: any): Promise<string | null> => {
  const res = await api(
    `smartlink/supplierAvailability`,
    { data, json: true, method: 'POST' },
    undefined
  )
  return res.data
}

export const getCurrentAvailaiblity = async (data: any): Promise<AvailabilityItem[]> => {
  const res = await api(
    `smartlink/getSupplierAvailabilityRecords`,
    { data, json: true, method: 'POST' },
    undefined
  )
  return res.data
}

export const uploadRequestInvoice = async (formData: FormData): Promise<string | null> => {
  // const res = await api(`smartlink/uploadInvoiceRequest`, data, undefined)

  const options = { headers: { 'Content-Type': 'multipart/form-data' }, method: 'POST' }
  const axiosOptions = { data: formData }
  const res = await api(`smartlink/uploadInvoiceRequest`, options, axiosOptions)
  return res.data
}
