
import { Component, Vue } from 'vue-property-decorator'
import api from '@/utilities/api'

@Component
export default class ViewPermit extends Vue {
  // data // ***
  loading = false
  permitData = null

  // mounted // ***
  mounted() {
    this.getPermit()
  }

  // computed // ***
  get id(): string {
    return this.$route.params.id
  }
  get validationCode(): string {
    return this.$route.params.validationCode
  }
  get permitBase64(): string {
    return `data:application/pdf;base64,${this.permitData}`
  }

  // methods // ***
  async getPermit() {
    this.loading = true
    const res = await api(
      `permit/print/public/${this.id}?validationCode=${this.validationCode}`,
      { method: 'GET' },
      { responseType: 'blob' }
    )
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([res?.data], { type: 'application/pdf' }))
    link.click()
    if (res?.data) this.permitData = res?.data
    this.loading = false
  }
}
