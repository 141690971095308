import { title } from '@/utilities/filters'
import { getFormattedDateTime } from '@/utilities/org'

export const headers = (context: Record<string, any>): Record<string, any>[] => {
  return [
    {
      renderFunction: (d: string): string => title(d),
      text: String(context.$t('common.agreementType')),
      value: 'agreementTypeName',
    },
    { text: title(String(context.$t('common.signedBy'))), value: 'signatory' },
    {
      renderFunction: (d: string): string => (d ? getFormattedDateTime(d) : ''),
      text: title(String(context.$t('common.signedOn'))),
      value: 'signedDate',
    },
    { text: title(String(context.$t('common.filename'))), value: 'filename' },
    {
      vueComponent: {
        name: 'c-table-action-button',
        props: {
          class: 'my-2',
          color: 'primary',
          small: true,
          text: context.$t('common.download'),
        },
      },
      value: '',
      width: '1%',
    },
  ]
}
