
import { Component, Vue } from 'vue-property-decorator'
import { getFormattedDateTime } from '@/utilities/org'
import { headers } from '@/pages/policies/helpers'
import api from '@/utilities/api'

@Component
export default class PoliciesAndTerms extends Vue {
  // data // ***
  values: Record<string, any> = {}
  policies: Record<string, any> = []
  loading = false

  // mounted // ***
  async mounted() {
    this.policies = await this.getPolicies()
  }
  // computed // ***
  get signatureNeeded() {
    if (this.$auth.impersonating) return false
    else return this.policies.some((x: { signatory: any }) => !x.signatory)
  }
  get nextPolicyToSign() {
    return this.policies.find((x: { signatory: any }) => !x.signatory)
  }
  get headers(): Record<string, any>[] {
    return headers(this)
  }
  get numberOfUnsignedPolicies() {
    return this.policies.filter((x: { signatory: any }) => !x.signatory).length
  }

  // methods // ***
  policyClicked(p: Record<string, any>) {
    Vue.set(this.values, p.id, true)
  }
  async getPolicies() {
    return (async () => {
      this.loading = true
      if (!this.$auth.activeUser?.supplierOrganisationAgreements?.length) return []
      let policies = await Promise.all(
        this.$auth.activeUser?.supplierOrganisationAgreements.map(
          async (x: Record<string, any>, n: number) => {
            return {
              id: n,
              ...x,
              dateString: getFormattedDateTime(x.signedDate),
              filename: x.path.split('/').slice(-1)[0],
            }
          }
        )
      )
      this.loading = false
      return policies
    })()
  }
  async getPdf(path: string) {
    if (!path) return null
    const { data } =
      (await api(
        `/organisationagreement/open`,
        { params: { path: path } },
        { responseType: 'blob' }
      )) || {}
    if (data) {
      return window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
    }
  }
  async submit(agreementId: number) {
    const message = String(this.$t('ui.policySignedX', { x: this.nextPolicyToSign.filename }))
    await this.$store.dispatch('core/acceptTermsAndConditions', agreementId)
    await this.$store.dispatch('core/fetchActiveUser')
    this.policies = await this.getPolicies()
    window.scrollTo(0, 0)
    this.$store.dispatch('core/launchSnackbar', { color: 'success', message })
  }
  download(e: any) {
    const link = document.createElement('a')
    link.href = e.item.path
    link.download = e.item.filename
    link.click()
  }
}
