
import { Component, Vue, Prop } from 'vue-property-decorator'
import { sendAvailaiblityResponse, getCurrentAvailaiblity } from '@/utilities/smartLink'
import { DateTime } from 'luxon'
import availabilityCoachComponent from './availabilityCoach.vue'
import availabilityTaxiComponent from './availabilityTaxi.vue'
import availabilityHotelComponent from './availabilityHotel.vue'
import { AvailabilityItem, SupplierAvailabilityRequestModel } from '@/models/AvailabilityItem'

@Component({
  components: {
    'availability-coach-component': availabilityCoachComponent,
    'availability-taxi-component': availabilityTaxiComponent,
    'availability-hotel-component': availabilityHotelComponent,
  },
})
export default class SmartLink extends Vue {
  // data // ***
  isloading = false
  isCompleted = false
  submittedBy = ''
  records = Array<AvailabilityItem>()
  // props // ***
  @Prop() readonly data: any
  @Prop() readonly link: any

  // computed // ***
  get supplierName() {
    return this.data ? this.data.supplierName : ''
  }

  get isCoach() {
    return this.data ? this.data.showCoach : false
  }

  get isTaxi() {
    return this.data ? this.data.showTaxi : false
  }

  get isHotel() {
    return this.data ? this.data.showHotel : false
  }

  get linkExpirationTime() {
    return this.data ? DateTime.fromISO(this.link.expiresDate).toFormat('HH:mm') : ''
  }

  get linkExpirationDate() {
    return this.data ? DateTime.fromISO(this.link.expiresDate).toFormat('DDDD') : ''
  }

  async mounted() {
    const model = new SupplierAvailabilityRequestModel()
    model.smartLinkId = this.link.id
    model.supplierId = this.data.organisationId
    this.records = await getCurrentAvailaiblity(model)
  }

  async submitAvailabiliy() {
    try {
      this.isloading = true
      let allItems = Array<AvailabilityItem>()
      if (this.isCoach)
        //@ts-ignore
        allItems = allItems.concat(this.$refs.coach.availabilityItems as AvailabilityItem[])
      if (this.isTaxi)
        //@ts-ignore
        allItems = allItems.concat(this.$refs.taxi.availabilityItems as AvailabilityItem[])
      if (this.isHotel)
        //@ts-ignore
        allItems = allItems.concat(this.$refs.hotel.availabilityItems as AvailabilityItem[])

      console.log(allItems)
      const model = new SupplierAvailabilityRequestModel()
      model.items = allItems
      model.smartLinkId = this.link.id
      model.submittedBy = this.submittedBy
      model.supplierId = this.data.organisationId
      await sendAvailaiblityResponse(model)
      this.isCompleted = true
    } catch (e) {
      console.error(e)
      const errors = this.$store.getters['core/validationErrors']
      const message = errors.join(', ')
      this.$store.dispatch('core/launchSnackbar', { color: 'error', message })
    } finally {
      this.isloading = false
    }
  }
}
