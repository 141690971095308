
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import availabilityItemComponent from './availabilityItem.vue'
import { AvailabilityItem, AvailabilityItemType } from '@/models/AvailabilityItem'

@Component({ components: { 'availability-item-component': availabilityItemComponent } })
export default class SmartLink extends Vue {
  @Prop() readonly isCoach: boolean = false
  @Prop() readonly existingRecords: AvailabilityItem[] | undefined

  @Watch('existingRecords')
  onChildChanged(val: AvailabilityItem[]) {
    for (const item of this.availabilityItems) {
      const record = val.find(x => x.itemTypeId == item.itemTypeId && x.type == item.type)
      if (record) {
        item.numberAvailable = record.numberAvailable
        item.rate = record.rate
      }
    }
  }

  availabilityItems = [
    new AvailabilityItem({
      description: 'Taxi',
      itemTypeId: 6,
      numberAvailable: 0,
      type: AvailabilityItemType.Taxi,
    }),
    new AvailabilityItem({
      description: 'Executive Car',
      itemTypeId: 129,
      numberAvailable: 0,
      type: AvailabilityItemType.Taxi,
    }),
    new AvailabilityItem({
      description: '4 x 4',
      itemTypeId: 1524,
      numberAvailable: 0,
      type: AvailabilityItemType.Taxi,
    }),
    new AvailabilityItem({
      description: 'MPV',
      itemTypeId: 1186,
      numberAvailable: 0,
      type: AvailabilityItemType.Taxi,
    }),
    new AvailabilityItem({
      description: 'DDA',
      itemTypeId: 81,
      numberAvailable: 0,
      type: AvailabilityItemType.Taxi,
    }),
  ]
  mouted() {
    if (!this.isCoach)
      this.availabilityItems.push(
        new AvailabilityItem({
          description: '8 Seater',
          itemTypeId: 2,
          numberAvailable: 0,
          type: AvailabilityItemType.Taxi,
          rate: 0,
        })
      )
  }
}
